<template>
  <b-sidebar
    id="add-sidebar-view"
    ref="sidebar_view"
    bg-variant="white"
    sidebar-class="sidebar-lg post-card modal-responsive-width post-viewpop"
    shadow
    backdrop
    no-header
    right
    @shown="getPostData(itemId)"
    @hidden="resetFormData()"
    @change="(val) => $emit('update:sidebarViewActive', val)"
    :visible="show"
  >
    <template  #default="{ hide }"> 
      <!-- Header -->
      <b-link @click="hide">
        <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" />
      </b-link>
      <b-card class="shadow-none bottom-body">
         <!-- Sumarized Post -->
         <div class="d-flex align-items-center" v-if="post_data.showSummary == true">
          <b-link class="back-original d-flex align-items-center mb-1" @click="post_data.showSummary = false">
            <feather-icon icon="ChevronLeftIcon" size="16" class="cursor-pointer"/>
            <span class="pl-25">Back to Original</span>
          </b-link> 
         </div>
        <!-- End Sumarized Post -->
        <div
          class="d-flex justify-content-between align-items-center mb-50 post-card post-header"
        >
          <div class="d-flex justify-content-start align-items-center">
            <!-- avatar -->
            <b-avatar
              :src="post_data.user.avatar"
              style="background: transparent"
              size="40"class="mr-75"
            />
            <!--/ avatar -->
            <div class="profile-user-info mt-25 cursor-pointer">
              <h6 class="mb-0">
                {{ post_data.user.name }}
              </h6>
              <small>
                {{ post_data.user.designation }}
              </small>
            </div>
          </div>
          <div class="d-none d-md-block ml-auto" v-if="post_data.is_published == 'true'">
            <b-row class="align-items-center">
            <b-img v-if="post_data.is_pinned == 'true'" :src="require('@/assets/images/icons/pin.svg')"
              size="24" class="m-1 pin-icon" style="background-color: transparent"
            v-b-tooltip.hover.v-default title="Post is pinned by admin" />
              <!--Summarized-->
              <b-button class="rewrite-aibtn mr-1" variant="outline-ai" v-if="post_data.is_published == 'true' && post_data.showSummary == false" @click="summarizeWithAI()"><b-img  :src="require('@/assets/images/icons/summarize.svg')" size="24"
                class=""/><span>Summarize</span></b-button>
              <!--End Summarized-->
              <p class="d-none d-md-block">{{ post_data.created_at | formatDate }}</p>
            </b-row>
          </div>
          <div class="d-none d-md-block ml-auto" v-if="post_data.is_scheduled == true &&
          post_data.is_published == 'false'">
          <b-badge class="d-none d-md-flex schedule-badge" variant="light-info" v-if="post_data.is_scheduled == true && post_data.is_published == 'false'">Scheduled : {{ post_data.created_at}} </b-badge>
          </div>
          
          <b-badge class="m-50 ml-auto" v-if="post_data.is_approved == 'false' &&
            post_data.is_published == 'true'
            " pill variant="light-danger">Need Approval</b-badge>

          <b-badge v-if="post_data.is_draft == 'true'" class="ml-auto"
            variant="light-secondary">Draft</b-badge>
          <b-dropdown class="blog-dropdown pl-1" variant="link"
            toggle-class="text-decoration-none p-0" no-caret  v-if="post_data.is_published == 'true'">
            <template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16"
                class="text-body align-middle mr-25 text-muted" />
            </template>

            <b-dropdown-item class="btn-sm p-0 text-muted"
              v-if="$can('Zircly Admin') && post_data.is_pinned == 'false'" @click="pinPost(post_data)">
              <b-img :src="require('@/assets/images/icons/pin.svg')" size="16" class="mr-50"
                style="background-color: transparent" />
              <span class="">Pin Post</span>
            </b-dropdown-item>
            <b-dropdown-item class="btn-sm p-0 text-muted"
              v-if="$can('Zircly Admin') && post_data.is_pinned == 'true'" @click="unpinPost(post_data)">
              <b-img :src="require('@/assets/images/icons/unpin.svg')" size="16" class="mr-50"
                style="background-color: transparent" />
              <span class="">Un-Pin Post</span>
            </b-dropdown-item>
                      <b-dropdown-item 
                              class="btn-sm p-0 text-muted"  
                              @click="savePost(post_data)"
                            >
                              <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="14" 
                                height="20" 
                                viewBox="0 0 14 20" 
                                fill="none" 
                                :class="post_data.youSaved ? 'saved-post' : 'profile-icon'"
                              >
                                <path 
                                  d="M13 5V19L7 15L1 19V5C1 3.93913 1.42143 2.92172 2.17157 2.17157C2.92172 1.42143 3.93913 1 5 1H9C10.0609 1 11.0783 1.42143 11.8284 2.17157C12.5786 2.92172 13 3.93913 13 5Z" 
                                  stroke="#344054" 
                                  stroke-width="1.5" 
                                  stroke-linecap="round" 
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <span class="ml-50">{{ post_data.youSaved ? 'Unsave Post' : 'Save Post' }}</span>
                            </b-dropdown-item>
            <b-dropdown-item class="btn-sm p-0 text-muted"
                        v-if="$can('Zircly Admin') && post_data.is_public == false" @click="postPublicURL(post_data)">
                        <b-img :src="require('@/assets/images/icons/publicurl/public-icon.svg')" width="20px" class="mr-50"
                          style="background-color: transparent;" />
                        <span class="">Generate Public URL</span>
                      </b-dropdown-item>

                      <b-dropdown-item class="btn-sm p-0 text-muted"
                        v-if="$can('Zircly Admin') && post_data.is_public == true" @click="postRevokePublicURL(post_data)">
                        <b-img :src="require('@/assets/images/icons/publicurl/revoke-icon.svg')" width="20px" class="mr-50"
                          style="background-color: transparent;" />
                        <span class="">Revoke Public URL</span>
                      </b-dropdown-item>

                      <b-dropdown-item class="btn-sm p-0 text-muted"
                        v-if="$can('Zircly Admin') && post_data.is_public == true" @click="copyPublicURL(post_data.public_post.public_post_id)">
                        <b-img :src="require('@/assets/images/icons/publicurl/public-icon.svg')" width="20px" class="mr-50"
                          style="background-color: transparent;" />
                        <span class="">Copy Public URL</span>
                      </b-dropdown-item>
            <b-dropdown-item class="btn-sm p-0 text-muted"
              @click="triggerEdit(post_data);" v-if="($can('post_edit') &&
                post_data.user.hashid == userData.hashid) ||
                $can('Zircly Admin')
                ">
              <feather-icon icon="EditIcon" size="13" class="mr-50" />
              <span class="">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item class="btn-sm p-0 text-muted" v-if="$can('Zircly Admin') &&
              post_data.is_approved == 'false' && userData.need_approval == 'true'
              " @click="approvePost(post_data)">
              <feather-icon icon="EditIcon" size="13" class="mr-50" />
              <span class="">Approve Post</span>
            </b-dropdown-item>
            <b-dropdown-item class="btn-sm p-0 text-muted" v-if="$can('Zircly Admin') &&
              post_data.is_approved == 'true' && userData.need_approval == 'true'
              " @click="rejectPost(post_data)">
              <feather-icon icon="EditIcon" size="13" class="mr-50" />
              <span class="">Reject Post</span>
            </b-dropdown-item>
            <b-dropdown-item v-clipboard:copy="shareUrl(post_data.hashid)" v-clipboard:success="onCopy"
              v-clipboard:error="onError" class="btn-sm p-0 text-muted">
              <feather-icon icon="CopyIcon" size="13" class="mr-50" />
              <span class="">Copy</span>
            </b-dropdown-item>

            <b-dropdown-item class="btn-sm p-0" @click="confirmDeleteRecord(post_data)" v-if="($can('post_delete') &&
              post_data.user.hashid == userData.hashid) ||
              $can('Zircly Admin')
              ">
              <feather-icon icon="TrashIcon" size="13" class="mr-50" />
              <span class="">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <!-- Text Post -->
        <div class="d-flex d-md-none mb-1">
          <b-badge pill variant="light-info"  v-if="post_data.is_scheduled == true && post_data.is_published == 'false'" >Scheduled : {{ post_data.created_at}} </b-badge>
        </div>
        <div class="post-body mb-1" v-if="post_data.other_details && post_data.other_details.post_type == 'text-post'">
          <div  class="" style="position: relative">
            <div>
              <b-card-text >
                <h2 class="card-header pb-1 mt-lg-0">{{ post_data.title }}</h2>
              </b-card-text>
              <!-- <b-card-text v-html="post_data.body"></b-card-text> -->

              <b-card-text v-if="post_data.showSummary == false" v-html="post_data.body"></b-card-text>
              <b-skeleton-wrapper v-else-if="post_data.showSummary && (!post_data.summaryData ||post_data.summaryData == false )" :loading="true" class="skeleton-loader">
                <template #loading>
                  <b-skeleton width="100%"></b-skeleton>
                  <b-skeleton width="100%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                </template>
              </b-skeleton-wrapper>
              <!-- End Skeleton Loader -->

              <b-card-text v-else v-html="post_data.summaryData" ></b-card-text>
              
              <div v-if="post_data.attachments.length">
                <h2 class="mt-2 mb-2">Attachments</h2>
                <div
                  class="mb-2 mt-2 d-flex justify-content-center justify-content-lg-start"
                  :key="'carousel-' + banner_index"
                  v-for="(banner, banner_index) in post_data.attachments"
                >
                  <b-img
                    v-if="banner.type == 'image/png' || banner.type == 'image/jpeg'"
                    :src="banner.link"
                    rounded
                    class="mb-25"
                    style="max-height: 400px; max-width: 100%"
                  />
                </div>
              </div>

              <div
                v-for="(video, video_index) in post_data.attachments"
                :key="'attachments-' + video_index"
              >
                <b-embed
                  v-if="video.type && video.type.startsWith('video/') && video.link"
                  type="video"
                  aspect="16by9"
                  controls
                  autoplay="false"
                  autostart="false"
                  class="rounded mb-50"
                >
                  <source :src="video.link" type="video/webm" />
                  <source :src="video.link" type="video/mp4" />
                </b-embed>
                <embed
                  class="mt-1 rounded"
                  v-else-if="video.type == 'application/pdf'"
                  :src="video.link"
                  frameBorder="0"
                  scrolling="auto"
                  width="100%"
                  style="min-height: 50vh"
                />
                <div
                  v-else-if="video.type == 'image/png' || video.type == 'image/jpeg'"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Text Post -->

        <!-- Photo Post -->
        <div class="post-body mb-1" v-if="post_data.other_details && post_data.other_details.post_type == 'photo-post'">
          <div  class="" style="position: relative">
            <div>
              
              <!-- post img -->
              <div class="mt-1 mb-1 d-flex" >
                <b-img fluid rounded :src="post_data.attachments[0].link" class="post-img" />
              </div>
              <b-card-text v-html="post_data.body"></b-card-text>
            </div>
          </div>
          
        </div>
        <!-- End Photo Post -->
        <!-- Video Post -->
        <div class="post-body mb-1" v-if="post_data.other_details && post_data.other_details.post_type == 'video-post'">
          <div  class="" style="position: relative">
            <div>
              
              <!-- post img -->
              <div class="mt-1 mb-1 d-flex" >
                <b-embed type="iframe" aspect="16by9" :src="post_data.attachments[0] && post_data.attachments[0].link" allowfullscreen/>
              </div>
              <b-card-text v-html="post_data.body"></b-card-text>
            </div>
          </div>
          
        </div>
        <!-- End Video Post -->

        <!-- Quote Post -->
        <div class="post-body mb-1" v-if="post_data.other_details && post_data.other_details.post_type == 'quote-post'">
          <div  class="quote-post" style="position: relative">
            <div>
              <b-card-text>
                {{ post_data.body }}
              </b-card-text>
              <p class="author-name my-1 pt-1" v-if="post_data.other_details.author"><strong>Author Name : {{post_data.other_details.author}}</strong></p>
            </div>
          </div>
        </div>
        <!-- {{ post_data }} -->
        <!-- End Quote Post -->
        <div class="d-flex justify-content-start mb-50 justify-content-between">
                  <div class="d-flex mobile-avatar align-items-center mb-50">
                    <b-avatar-group size="20">
                      <b-avatar
                        v-for="(user, i) in post_data.likes"
                        v-if="i < 3"
                        :key="'avatar-' + i"
                        v-b-tooltip.hover.top="user.user.hashid == userData.hashid ? 'You' : user.user.name"
                        class="pull-up m-0"
                        :src="user.user.avatar"
                      />
                    </b-avatar-group>
                    <span
                    v-if="post_data.latest_like && post_data.likes_count > 1"
                    class="reactions-count ml-50"
                    v-b-modal="'show-likes-' + post_data.hashid"
                  >
                    {{ post_data.youLiked ? "You" : post_data.latest_like.user.name }} and {{ post_data.likes_count - 1 }} others
                  </span>
                  <span
                    v-if="post_data.latest_like && post_data.likes_count <= 1"
                    class="reactions-count ml-50"
                    v-b-modal="'show-likes-' + post_data.hashid"
                  >
                    {{ post_data.youLiked ? 'Liked by You' : 'Liked by ' + post_data.latest_like.user.name }}
                  </span>
                </div>
                  <div class="overall-cmt cursor-pointer"  v-if="post_data.comments_count > 0">
                    <span>{{ kFormatter(post_data.comments_count) }}</span> <span>Comments</span>
                  </div>
                  <div class="overall-cmt cursor-pointer"  v-else-if="post_data.comments_count > 0">
                    <span>{{ kFormatter(post_data.comments_count) }}</span> <span>Comments</span>
                  </div>
                </div>
        <!-- likes comments  share-->
        <b-row class="m-0 like-button top-cmd" v-if="post_data.is_published == 'true'">
          <b-col cols="8" class="d-flex justify-content-start mb-0 pl-0 left-cmt">
            <b-link class="d-flex align-items-center text-muted text-nowrap post-likes">
              <div class="likes" v-if="!post_data.youLiked">
                <!-- <feather-icon
                  icon="HeartIcon"
                  class="mr-50 text-primary profile-icon"
                  size="24"
                  title="Like"
                  @click="likePost(post_data, 'like')"
                /> -->
                <!-- <b-img
                  :src="require('@/assets/images/icons/like/beforeLike.png')"
                  width="24"
                  title="Like"
                  class="mr-50 active-like"
                  @click="likePost(post_data, 'like')"
                ></b-img> -->
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20" fill="none" title="Like" class="active-like">
                  <g>
                    <path d="M5.83333 9.16671V15.8334C5.83333 16.0544 5.74554 16.2663 5.58926 16.4226C5.43298 16.5789 5.22101 16.6667 5 16.6667H3.33333C3.11232 16.6667 2.90036 16.5789 2.74408 16.4226C2.5878 16.2663 2.5 16.0544 2.5 15.8334V10C2.5 9.77903 2.5878 9.56707 2.74408 9.41079C2.90036 9.2545 3.11232 9.16671 3.33333 9.16671H5.83333ZM5.83333 9.16671C6.71739 9.16671 7.56523 8.81552 8.19036 8.1904C8.81548 7.56528 9.16667 6.71743 9.16667 5.83337V5.00004C9.16667 4.55801 9.34226 4.13409 9.65482 3.82153C9.96738 3.50897 10.3913 3.33337 10.8333 3.33337C11.2754 3.33337 11.6993 3.50897 12.0118 3.82153C12.3244 4.13409 12.5 4.55801 12.5 5.00004V9.16671H15C15.442 9.16671 15.866 9.3423 16.1785 9.65486C16.4911 9.96742 16.6667 10.3913 16.6667 10.8334L15.8333 15C15.7135 15.5113 15.4861 15.9502 15.1855 16.2508C14.8849 16.5514 14.5274 16.6974 14.1667 16.6667H8.33333C7.67029 16.6667 7.03441 16.4033 6.56557 15.9345C6.09673 15.4656 5.83333 14.8297 5.83333 14.1667" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                  </g>
                  <defs>
                    <clipPath>
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div class="emoji-reactions">
                  <div class="emoji-item" @click="likePost(post_data,'like')" v-b-tooltip.hover.v-default title="Like">
                    <b-img :src="require('@/assets/images/icons/like/like.svg')" width="26" class="default-icon"></b-img>
                    <b-img :src="require('@/assets/images/icons/like/like.gif')" width="26" class="hover-icon"></b-img>
                  </div>
                  <div class="emoji-item" @click="likePost(post_data,'clap')" v-b-tooltip.hover.v-default title="Appreciate">
                    <b-img :src="require('@/assets/images/icons/like/appreciate.svg')" width="26" class="default-icon"></b-img>
                    <b-img :src="require('@/assets/images/icons/like/appreciate.gif')" width="26" class="hover-icon"></b-img>
                  </div>
                  <div class="emoji-item" @click="likePost(post_data,'love')" v-b-tooltip.hover.v-default title="Love">
                    <b-img :src="require('@/assets/images/icons/like/love.svg')" width="26" class="default-icon"></b-img>
                    <b-img :src="require('@/assets/images/icons/like/love.gif')" width="26" class="hover-icon"></b-img>
                  </div>
                  <div class="emoji-item" @click="likePost(post_data,'celebrate')" v-b-tooltip.hover.v-default title="Celebrate">
                    <b-img :src="require('@/assets/images/icons/like/celebrate.svg')" width="26" class="default-icon"></b-img>
                    <b-img :src="require('@/assets/images/icons/like/celebrate.gif')" width="26" class="hover-icon"></b-img>
                  </div>
                  <div class="emoji-item" @click="likePost(post_data,'idea')" v-b-tooltip.hover.v-default title="Insightful">
                    <b-img :src="require('@/assets/images/icons/like/insightful.svg')" width="26" class="default-icon"></b-img>
                    <b-img :src="require('@/assets/images/icons/like/insightful.gif')" width="26" class="hover-icon"></b-img>
                  </div>
                  <div class="emoji-item" @click="likePost(post_data,'laugh')" v-b-tooltip.hover.v-default title="Laugh">
                    <b-img :src="require('@/assets/images/icons/like/laugh.svg')" width="26" class="default-icon"></b-img>
                    <b-img :src="require('@/assets/images/icons/like/laugh.gif')" width="26" class="hover-icon"></b-img>
                  </div>
                </div>
              </div>
              <div class="likes" v-if="post_data.youLiked && post_data.likeType">
                <div v-if="post_data.likeType == 'like'" @click="likePost(post_data,'like')">
                  <b-img :src="require('@/assets/images/icons/like/like.gif')" width="24" title="Like" class="like-select"></b-img>
                </div>
                <div v-else-if="post_data.likeType == 'clap'" @click="likePost(post_data,'clap')">
                  <b-img :src="require('@/assets/images/icons/like/appreciate.gif')" width="24" title="Appreciate" class=""></b-img>
                </div>
                <div v-else-if="post_data.likeType == 'love'" @click="likePost(post_data,'love')">
                  <b-img :src="require('@/assets/images/icons/like/love.gif')" width="24" title="Love" class=""></b-img>
                </div>
                <div v-else-if="post_data.likeType == 'celebrate'" @click="likePost(post_data,'celebrate')">
                  <b-img :src="require('@/assets/images/icons/like/celebrate.gif')" width="24" title="Celebrate" class=""></b-img>
                </div>
                <div v-else-if="post_data.likeType == 'idea'" @click="likePost(post_data,'idea')">
                  <b-img :src="require('@/assets/images/icons/like/insightful.gif')" width="24" title="Insightful" class="active-idea"></b-img>
                </div>
                <div v-else-if="post_data.likeType == 'laugh'" @click="likePost(post_data,'laugh')">
                  <b-img :src="require('@/assets/images/icons/like/laugh.gif')" width="24" title="Laugh" class=""></b-img>
                </div>
                <div v-else @click="likePost(post_data,'like')">
                  <b-img :src="require('@/assets/images/icons/like/like.gif')" width="24" title="Like" class=""></b-img>
                </div>
                        <div class="emoji-reactions">
                          <div class="emoji-item" @click="likePost(post_data,'like')" v-b-tooltip.hover.v-default title="Like">
                            <b-img :src="require('@/assets/images/icons/like/like.svg')" width="26" class="default-icon"></b-img>
                            <b-img :src="require('@/assets/images/icons/like/like.gif')" width="26" class="hover-icon"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(post_data,'clap')" v-b-tooltip.hover.v-default title="Appreciate">
                            <b-img :src="require('@/assets/images/icons/like/appreciate.svg')" width="26" class="default-icon"></b-img>
                            <b-img :src="require('@/assets/images/icons/like/appreciate.gif')" width="26" class="hover-icon"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(post_data,'love')" v-b-tooltip.hover.v-default title="Love">
                            <b-img :src="require('@/assets/images/icons/like/love.svg')" width="26" class="default-icon"></b-img>
                            <b-img :src="require('@/assets/images/icons/like/love.gif')" width="26"class="hover-icon"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(post_data,'celebrate')" v-b-tooltip.hover.v-default title="Celebrate">
                            <b-img :src="require('@/assets/images/icons/like/celebrate.svg')" width="26" class="default-icon"></b-img>
                            <b-img :src="require('@/assets/images/icons/like/celebrate.gif')" width="26" class="hover-icon"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(post_data,'idea')" v-b-tooltip.hover.v-default title="Insightful">
                            <b-img :src="require('@/assets/images/icons/like/insightful.svg')" width="26" class="default-icon"></b-img>
                            <b-img :src="require('@/assets/images/icons/like/insightful.gif')" width="26" class="hover-icon"></b-img>
                          </div>
                          <div class="emoji-item" @click="likePost(post_data, 'laugh')" v-b-tooltip.hover.v-default title="Laugh">
                            <b-img :src="require('@/assets/images/icons/like/laugh.svg')" width="26" class="default-icon"></b-img>
                            <b-img :src="require('@/assets/images/icons/like/laugh.gif')" width="26" class="hover-icon"></b-img>
                          </div>
                        </div>
                      </div>
              <span class="pl-25 change-reaction" v-if="post_data.likeType"
                :style="{
                  color: post_data.likeType === 'like' ? '#B54708' :
                    post_data.likeType == 'idea' ? '#0E7090' :
                    post_data.likeType == 'love' ? '#D92D20' :
                    post_data.likeType == 'clap' ? '#B54708' :
                    post_data.likeType == 'laugh' ? '#B54708' :
                    post_data.likeType == 'celebrate' ? '#B54708' : ''
                }">
                {{ post_data.likeType == 'clap' ? 'Appreciate' :
                post_data.likeType == 'idea' ? 'Insightful' :
                post_data.likeType }}
              </span>
              <!-- <span
                v-if="post_data.likes_count"
                v-b-modal="'show-likes-' + post_data.hashid"
                style="margin-left: 4px"
                >{{ kFormatter(post_data.likes_count) }}</span
              > -->
              <span class="pl-25 change-reaction" v-else>Like</span>
            </b-link>
            <b-link class="text-nowrap d-flex align-items-center ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none" class="profile-icon mr-25">
                <g clip-path="url(#clip0_12481_37483)">
                  <path d="M17.2222 4C17.9766 4 18.7001 4.29748 19.2335 4.82699C19.767 5.35651 20.0667 6.07468 20.0667 6.82353V14.3529C20.0667 15.1018 19.767 15.82 19.2335 16.3495C18.7001 16.879 17.9766 17.1765 17.2222 17.1765H12.4815L7.74074 20V17.1765H5.84444C5.09005 17.1765 4.36656 16.879 3.83312 16.3495C3.29968 15.82 3 15.1018 3 14.3529V6.82353C3 6.07468 3.29968 5.35651 3.83312 4.82699C4.36656 4.29748 5.09005 4 5.84444 4H17.2222Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_12481_37483">
                    <rect width="24" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <span>Comment</span>
            </b-link>
          </b-col>
          <b-col cols="4" class="d-flex justify-content-end align-items-center mb-0 right-icons pr-0">
            <!-- <b-link class="text-body text-nowrap">
              <feather-icon icon="MessageCircleIcon" size="20" class="profile-icon mr-50 text-primary" />
              <span v-if="post_data.comments_count" class="text-muted">{{
                kFormatter(post_data.comments_count)
              }}</span>
            </b-link>
            <b-link
              v-if="post_data.visit_count_total"
              class="text-body text-nowrap d-flex align-items-center mx-2"
              @click="showVisits(post_data.hashid)"
            >
              <feather-icon
                icon="EyeIcon"
                size="20"
                class="profile-icon mr-50 text-primary"
              />
              <span class="text-muted">{{
                kFormatter(post_data.visit_count_total)
              }}</span>
            </b-link> -->
            <!-- <b-link
                  v-if="post_data.comments_count > 0"
                  v-b-toggle.add-sidebar-view
                  class="text-body text-nowrap d-flex align-items-center"
                >
                  <feather-icon
                    icon="MessageCircleIcon"
                    size="20"
                    class="profile-icon mr-25 text-primary"
                    v-b-tooltip.hover.v-default
                    title="Comments"
                  />
                  <span class="text-muted">{{ kFormatter(post_data.comments_count) }}</span>
                </b-link> -->
                <!-- <feather-icon
                  v-else
                  icon="MessageCircleIcon"
                  size="20"
                  class="profile-icon mr-25 text-primary"
                  v-b-tooltip.hover.v-default
                  title="Comments"
                  style="cursor: pointer;"
                /> -->
                <!-- <b-link v-if="post_data.visit_count_total > 0" class="text-body text-nowrap d-flex align-items-center mx-1"
                      @click="showVisits(post_data.hashid)">
                      <feather-icon icon="EyeIcon" size="20" class="profile-icon mr-25 text-primary"
                        v-b-tooltip.hover.v-default title="Views" />
                      <span class="text-muted">{{
                        kFormatter(post_data.visit_count_total)
                      }}</span>
                </b-link>
                    <feather-icon
                  v-else
                  icon="EyeIcon"
                  size="20"
                  class="profile-icon mr-25 text-primary"
                  v-b-tooltip.hover.v-default
                  title="Views"
                  style="cursor: pointer;"
                /> -->
            <!-- <b-modal
              :id="'open-visits-' + post_data.hashid"
              :ref="'open-visits-' + post_data.hashid"
              hide-footer
              modal-class="modal-primary"
              scrollable
              title="Views"
              class="text-danger"
            >
              <b-list-group v-if="postViews.length">
                <b-list-group-item
                  v-for="user in postViews"
                  :key="'user-' + user.hashid"
                  class="d-flex align-items-center"
                >
                  <b-avatar :src="user.avatar" size="24" class="mr-75" />
                  {{ user.name }}
                </b-list-group-item>
              </b-list-group>
              <b-card-text v-else-if="isBusy">
                <div class="d-flex align-items-center">
                  <strong>Loading...</strong>
                  <b-spinner class="ml-auto" variant="primary" />
                </div>
              </b-card-text>
              <b-card-text v-else>
                <div class="item error text-center my-2 empty-data">
                  <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50" style="background-color: transparent" />
                  <h4> OH NO!</h4>
                  <p class="d-block mb-1">No Records Found!</p>
                </div>
              </b-card-text>
            </b-modal> -->


            <b-modal :id="'open-visits-' + post_data.hashid" :ref="'open-visits-' + post_data.hashid"  hide-footer  modal-class="open-visits show-visits" scrollable class="text-danger" :hide-header-close="false" centered >
                  <template #modal-header="{ close }">
                    <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" @click="$bvModal.hide('open-visits-' + post_data.hashid)"/>
                  </template>
                  <b-tabs>
                    <!-- Seen Tab -->
                    <b-tab :title="'Seen (' + (postViews ? postViews.length : 0) + ')' " active>
                      <b-list-group v-if="postViews && postViews.length">
                        <b-list-group-item v-for="user in postViews" :key="'user-' + user.hashid" class="d-flex align-items-center px-0">
                          <b-avatar :href="`/profile/${user.hashid}`" :src="user.avatar" size="24" class="mr-75" />
                          <div class="profile-user-info">
                            <h6 class="mb-0">                      
                              {{ user.name }}
                            </h6>
                            <small>
                              {{ user.designation }}
                            </small>
                          </div>
                        </b-list-group-item>
                      </b-list-group>

                      <b-card-text v-else-if="isBusy">
                        <!-- <div class="d-flex align-items-center">
                          <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150" class="mr-50" />
                          <h4>Loading</h4>
                        </div> -->
                        <ul class="m-0 p-0 list-unstyled">
                          <li>
                            <div class="d-flex align-items-center">
                              <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                              <div class="profile-user-info w-100 mt-1">
                                <h6 class="mb-0">                      
                                  <b-skeleton  width="80%"></b-skeleton>
                                </h6>
                                <small>
                                  <b-skeleton  width="50%"></b-skeleton>
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                              <div class="profile-user-info w-100 mt-1">
                                <h6 class="mb-0">                      
                                  <b-skeleton  width="80%"></b-skeleton>
                                </h6>
                                <small>
                                  <b-skeleton  width="50%"></b-skeleton>
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                              <div class="profile-user-info w-100 mt-1">
                                <h6 class="mb-0">                      
                                  <b-skeleton  width="80%"></b-skeleton>
                                </h6>
                                <small>
                                  <b-skeleton  width="50%"></b-skeleton>
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                              <div class="profile-user-info w-100 mt-1">
                                <h6 class="mb-0">                      
                                  <b-skeleton  width="80%"></b-skeleton>
                                </h6>
                                <small>
                                  <b-skeleton  width="50%"></b-skeleton>
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                              <div class="profile-user-info w-100 mt-1">
                                <h6 class="mb-0">                      
                                  <b-skeleton  width="80%"></b-skeleton>
                                </h6>
                                <small>
                                  <b-skeleton  width="50%"></b-skeleton>
                                </small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <b-skeleton type="avatar" size="40" class="mr-50"></b-skeleton>
                              <div class="profile-user-info w-100 mt-1">
                                <h6 class="mb-0">                      
                                  <b-skeleton  width="80%"></b-skeleton>
                                </h6>
                                <small>
                                  <b-skeleton  width="50%"></b-skeleton>
                                </small>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </b-card-text>

                      <b-card-text v-else>
                        <div class="item error text-center my-2 empty-data">
                          <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50" />
                          <p class="d-block mb-1">No Records Found!</p>
                        </div>
                      </b-card-text>
                    </b-tab>

                    <!-- Unseen Tab -->
                    <b-tab :title="'Unseen (' + (postNotVisited ? postNotVisited.length : 0) + ')'">
                      <b-list-group v-if="postNotVisited && postNotVisited.length">
                        <b-list-group-item v-for="user in postNotVisited" :key="'user-' + user.hashid" class="d-flex align-items-center">
                          <b-avatar :href="`/profile/${user.hashid}`" :src="user.avatar" size="24" class="mr-75" />
                          <h6 class="p-0 m-0">{{ user.name }}</h6>
                        </b-list-group-item>
                      </b-list-group>

                      <b-card-text v-else-if="isBusy">
                        <div class="d-flex align-items-center">
                          <b-img :src="require('@/assets/images/empty-data/zircly-loader.gif')" width="150" class="mr-50" />
                          <h4>Loading</h4>
                        </div>
                      </b-card-text>

                      <b-card-text v-else>
                        <div class="item error text-center my-2 empty-data">
                          <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50" />
                          <p class="d-block mb-1">No Records Found!</p>
                        </div>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                  </b-modal>
            <!--<b-link class="text-body text-nowrap">
            <feather-icon
              icon="Share2Icon"
              size="18"
              class="profile-icon mr-50"
            />
            <span class="text-muted">{{ kFormatter(post_data.share) }}</span>
          </b-link>-->
          <b-link class="d-flex align-items-center">
            <feather-icon icon="TrendingUpIcon" size="20" class="profile-icon mr-50 cursor-pointer" stroke-width="1.5" v-if="post_data.visit_count_total > 0" @click="showVisits(post_data.hashid)" v-b-tooltip.hover.v-default title="Views"/>
              <span class="cursor-pointer mr-1" v-if="post_data.visit_count_total > 0" @click="showVisits(post_data.hashid)" >{{post_data.visit_count_total}}</span>
              <!-- <svg  @click="savePost(post_data)"
              xmlns="http://www.w3.org/2000/svg" 
              width="14" 
              height="20" 
              viewBox="0 0 14 20" 
              fill="none" 
              :class="post_data.youSaved ? 'saved-post' : 'profile-icon'"
            > -->
              <!-- <path 
                d="M13 5V19L7 15L1 19V5C1 3.93913 1.42143 2.92172 2.17157 2.17157C2.92172 1.42143 3.93913 1 5 1H9C10.0609 1 11.0783 1.42143 11.8284 2.17157C12.5786 2.92172 13 3.93913 13 5Z" 
                stroke="#667085" 
                stroke-width="1.5" 
                stroke-linecap="round" 
                stroke-linejoin="round"
              />
            </svg> -->
          </b-link>
          </b-col>
        </b-row>
        <b-row class="mt-1 like-button top-cmd delayed-post py-1" @click.stop v-else>
          <b-col sm="12" class="d-flex justify-content-end align-items-center">
            <div v-if="post_data.is_scheduled == false" class="draft-post d-flex justify-content-end py-1">
              <b-button variant="outline-secondary" pill class="delete-btn"  @click="confirmDeleteRecord(post_data)" v-if="($can('post_delete') &&
              post_data.user.hashid == userData.hashid) ||
              $can('Zircly Admin')
              " >Delete</b-button>
              <b-button variant="primary" class="edit-btn opacity-100 ml-1" pill @click="triggerEdit(post_data);" v-if="($can('post_edit') &&
              post_data.user.hashid == userData.hashid) ||
              $can('Zircly Admin')
              " >Edit</b-button>
            </div>
            <div v-if="post_data.is_scheduled == true" class="post-schedule d-flex justify-content-end align-items-center">
              <b-button variant="outline-secondary" pill class="delete-btn"  @click="confirmDeleteRecord(post_data)" v-if="($can('post_delete') &&
              post_data.user.hashid == userData.hashid) ||
                                $can('Zircly Admin')
                                " >Delete</b-button>
              <b-button variant="outline-secondary" pill class="edit-btn ml-1" @click="triggerEdit(post_data);" v-if="($can('post_edit') &&
              post_data.user.hashid == userData.hashid) ||
              $can('Zircly Admin')
              " >Edit</b-button>
              <b-button variant="primary" class="post-btn opacity-100 ml-1" pill @click="triggerPostNow(post_data);" v-if="($can('post_edit') &&
              post_data.user.hashid == userData.hashid) ||
              $can('Zircly Admin')
              ">Post Now</b-button>
            </div>
          </b-col>
        </b-row>
        <!--/ likes comments  share-->
        <template #footer v-if="post_data.is_published == 'true'" class="p-0 border-0 mb-1">
          <div class="comment-details" v-if="post_data">
       
            <comments :post="post_data" :index="post_data.hashid" :employeeListOptions.sync="employeeListOptions" 
                  :show-comment-box="true" 
                  :top-three="true"
                  :current-post-index="post_data.hashid" 
                  :update-post-data="updatePostData"
                   showComment="all"
                   
                   />


              <!-- <CommentBox
                placeholder="Share your thoughts..."
                className="w-100 comment-box pt-25"
                :id="'comment-field-' + post_data.hashid"
                :toolbar="false"
                :mentionList="employeeListOptions"
              />
              <b-button variant="primary" @click="addComment(post_data)" class="blog-btn-block search-post m-0" :disabled="commentSubmitClicked">Comment</b-button> -->
            </div>
              <!-- <b-button
                variant="primary"
                @click="addComment(post_data)"
                class="blog-btn-block mr-1 search-post rounded-circle p-75"
                :disabled="commentSubmitClicked"
              >
              <b-img :src="require('@/assets/images/icons/post-cmt-white.svg')" width="26"></b-img>
            </b-button> -->
           
            <!-- comments -->
               
            <!--/ comments -->
        
        </template>
        <b-overlay :show="isBusy" no-wrap> </b-overlay>
      </b-card>
      <b-modal
        id="summary"
        ok-only
        ok-title="Close"
        centered
        size="lg"
        :title="'Summary:'+post_data.title"
      >
        <b-card-text v-if="summaryData">
          {{ summaryData }}
        </b-card-text>

        <b-card-text v-else>
          <div class="d-flex align-items-center">
            <strong>Loading...</strong>
            <b-spinner class="ml-auto" variant="primary" />
          </div>
        </b-card-text>
      </b-modal>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BImg,
  BEmbed,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import { kFormatter } from "@core/utils/filter";
import { bus } from "@/main.js";
import axios from "@axios";
// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import Replies from "./Replies.vue";
import Comments from "./Comments.vue";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import QuillInput from "@/components/QuillInput.vue";
import CommentBox from "@/components/CommentBox.vue";
import $ from "jquery";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BSidebar,
    BImg,
    BEmbed,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
   
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    FilePond,
    Replies,
    QuillInput,
    CommentBox,
    Comments,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  model: {
    prop: "sidebarViewActive",
    event: "update:sidebarViewActive",
  },
  props: {
    itemId: {
      type: String,
      required: false,
      default: "",
    },
    sidebarViewActive: {
      type: Boolean,
      required: true,
      default: true,
    },
    itemData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    employeeListOptions: {
      type: Array,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      summaryData:null,
      stateObj:null,
      isBusy: true,
      data_local: {
        channel_type: "community",
      },
      show: false,
      customFields: [],
      support_categories: [],
      priority_option: [],
      status_option: [],
      myFiles: [],
      channel_types: [],
      channel_type_select: [],
      post_data: {
        user: {},
        attachments: {},
      },
      comment_id: null,
      reply_to: null,
      userData: this.$cookies.get("userData"),
      editComments: [],
      postViews: [],
      postNotVisited:[],
      postData: null,
      commentSubmitClicked:false,
      hashPostTypes: [
        "####photo-post####",
        "####video-post####",
        "####quote-post####",
      ],
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-tickets";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      // store.registerModule(USER_APP_STORE_MODULE_NAME, ticketStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  computed: {},
  watch: {
  sidebarViewActive(val) {
    const body = document.body;

    if (val) {
      body.classList.add('modal-open');

      if (this.itemId) {
          this.stateObj = { postId: this.itemId };
          const title = 'Post Page';
          const url = `?post_hash_id=${this.stateObj.postId}`; // Update URL format as needed
          history.replaceState(this.stateObj, title, url);
        }
    } else {
      body.classList.remove('modal-open');

      this.stateObj = { postId: this.itemId };
      var title = 'Community Home Page'; // Update the URL format as needed
      history.replaceState(this.stateObj, title, this.$route.path);
      this.$refs.sidebar_view.hide();
    }

    this.show = val;
    this.post_data = {
      user: {},
      attachments: {},
    };
    this.data_local = {
      channel_type: "community",
    };
    this.myFiles = [];
    if (this.itemId && this.itemData) {
      this.post_data = this.itemData;
      this.isBusy = false;
    }
  },

  itemId(val) {
    if (this.sidebarViewActive) {
      const body = document.body;
      body.classList.add('modal-open');
      if (this.itemId) {
          this.stateObj = { postId: this.itemId };
          const title = 'Post Page';
          const url = `?post_hash_id=${this.stateObj.postId}`; // Update URL format as needed
          history.replaceState(this.stateObj, title, url);
        }
    } else {
      const body = document.body;
      body.classList.remove('modal-open');

      this.stateObj = { postId: this.itemId };
      var title = 'Community Home Page'; // Update the URL format as needed
      history.replaceState(this.stateObj, title, this.$route.path);
      this.$refs.sidebar_view.hide();
    }
  }
},

  created() {
    bus.$on('toggle-sidebar', (visible) => {
      this.show = visible;
    })
  },
  mounted() {
    if (this.$route.query.post_hash_id) {
      // this.getPostData(this.$route.query.post_hash_id);
      this.itemId = this.$route.query.post_hash_id;
      this.show = true;
    }
  },
  methods: {
    savePost(data) {
      if (data.youSaved) {
        this.$http.delete(`/saved-models/${data.hashid}/unsave`).then((res) => {
          bus.$emit("loadPosts");
          this.getPostData(this.itemId);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post removed from saved posts",
          //     icon: "XCircleIcon",
          //     variant: "danger",
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Post removed from saved posts`,
                icon:'XCircleIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
      } else {
        this.$http.post(`/saved-models/${data.hashid}/save`).then((res) => {
          bus.$emit("loadPosts");
          this.getPostData(this.itemId);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post added to saved posts",
          //     icon: "CheckCircleIcon",
          //     variant: "primary",
          //   },
          // });
          this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Post added to saved posts',
          icon: 'CheckCircleIcon',
          variant: 'success',
          hideClose: true,
        },
      }, {
        timeout: 2000, 
        position: 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,
      });
        });
      }
    },
    summarizeWithAI(){
      this.post_data.showSummary = true;
      if(this.post_data.summaryData == false){
      axios.post("/summerizeChatGPT", {'post_id':this.post_data.hashid, 'body':this.post_data.body})
        .then((res) => {
          this.post_data.summaryData = res.data.response;
        })
        .catch((err) => {
          this.post_data.showSummary = false;
          console.log(err);
          console.log(err.toString());
        });
      }
    },
    shareUrl(hash_id) {
      return window.location.origin + "?post_hash_id=" + hash_id;
    },
    onCopy: function (e) {
      this.$toast.success("You just copied URL to the clipboard");
    },
    onError: function (e) {
      this.$toast.error("Failed to copy the URL to the clipboard");
      console.log(e);
    },
    showVisits(postId) {
      const self = this;
      let data = {};
      data.url = "/post-visits/" + postId;
      self.isBusy = true;
      self.postViews = [];
      self.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.postViews = res.data.data;
          var modal = "open-visits-" + postId;
          self.$bvModal.show(modal);
          self.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    kFormatter,
    groupCommentLikes(comments) {
      this.commentLikes = null;
      const types = ['like', 'clap', 'love', 'celebrate', 'idea', 'laugh'];

const groupedComments = comments.map(comment => {
    const groupedReactions = types.reduce((acc, type) => {
        acc[type] = []; 
        return acc;
    }, {});

    comment.likes.forEach(reaction => {
        if (groupedReactions.hasOwnProperty(reaction.type)) {
            groupedReactions[reaction.type].push(reaction);
        }
    });

    groupedReactions['all'] = comment.likes;

    return {
        groupedLikes: groupedReactions
    };
});
this.commentLikes = groupedComments[0].groupedLikes

return groupedComments;
    },
    getUniqueLikes(likes) {
      const seenTypes = new Set();
      return likes.filter(like => {
        if (!seenTypes.has(like.type)) {
          seenTypes.add(like.type);
          return true;
        }
        return false;
      });
    },
    getAvatarSrc(type) {
      const icons = {
        like: require('@/assets/images/icons/like/like.svg'),
        clap: require('@/assets/images/icons/like/appreciate.svg'),
        love: require('@/assets/images/icons/like/love.svg'),
        celebrate: require('@/assets/images/icons/like/celebrate.svg'),
        idea: require('@/assets/images/icons/like/insightful.svg'),
        smile: require('@/assets/images/icons/like/laugh.svg')
      };
      return icons[type] || '';
    },
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    handleCommentUpdate(val) {
      this.show = val; 
    },
    getNames(users) {
      let str = "";
      users.forEach((user) => {
        str += "<br />" + user.name;
      });
      return str;
    },

    loadMoreComments(){
      if (this.currentCount >= this.post.latest_comments.length) {
            this.$emit('view-post', this.post);
            // bus.$emit('toggle-sidebar', true);
          
      } else {
        this.currentCount = Math.min(this.currentCount + 3, this.post.latest_comments.length);
      }
    },

    addReply(data, index) {
      const self = this;
      self.comment_id = data.hashid;
      self.reply_to = data.user.name;
      self.comment_post_index = index;
      document.getElementById("comment-field-" + index).focus();
    },
    removeReply() {
      const self = this;
      self.comment_id = null;
      self.reply_to = data.null;
    },
    getNames(users) {
      let str = "";
      users.forEach((user) => {
        str += "<br />" + user.name;
      });
      return str;
    },
    likeComment(comment, index, type) {
      if (comment.youLiked && comment.likeType == type) {
        this.$http.delete(`/comments/${comment.hashid}/like`).then((res) => {
          // this.$parent.updatePostArray(res.data.data, index);
          comment.youLiked = false;
          comment.likeType = false;
          this.$set(this.post_data.comments, index, comment);
        });
      } else {
        this.$http
          .post(`/comments/${comment.hashid}/like/${type}`)
          .then((res) => {
            comment.likeType = type;
            comment.youLiked = true;
            this.$set(this.post_data.comments, index, comment);
          });
      }
      this.parentRefresh();
    },

    

    likePost(data, type) {
      if (data.youLiked && data.likeType == type) {
        this.$http.delete(`/posts/${data.hashid}/like`).then((res) => {
          data.youLiked = false;
          data.likes_count--;
          this.updatePostArray(res.data.data);
        });
      } else {
        this.$http.post(`/posts/${data.hashid}/like/${type}`).then((res) => {
          data.youLiked = true;
          data.likeType = type;
          data.likes_count++;
          this.updatePostArray(res.data.data);
        });
      }
      this.parentRefresh();
    },
    deleteComment(commentId) {
      this.$http
        .delete(`/posts/${this.itemId}/comments/${commentId}`)
        .then((res) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Comment deleted successfully",
          //     icon: "BellIcon",
          //     variant: "success",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment deleted successfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"successful-info",
              hideProgressBar : true,
            });
          this.getPostData(this.itemId);
          this.updatePostArray(res.data.data);
        });
      this.parentRefresh();

    },
    editComment(comment) {
      this.commentSubmitClicked = true;
      const self = this;
      const message = document.getElementById(
        `comment-field-${comment.hashid}`
      ).value;
      if (message === "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Comment adding Failed",
        //     icon: "BellIcon",
        //     text: "Please enter the comments",
        //     variant: "danger",
        //   },
        // });

        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Please enter the comments",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        this.commentSubmitClicked = false;
        return false;
      }

      let params = {};
      params = {
        comment: message,
      };

      this.$http
        .patch(`/comments/${comment.hashid}`, params)
        .then((res) => {
          this.editComments.pop();
          this.getPostData(this.itemId);
          this.commentSubmitClicked = false;
        })
        .catch((error) => {
          console.log(error);
          this.commentSubmitClicked = false;
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "Comment adding Failed",
                  //     icon: "BellIcon",
                  //     text: values[0][0],
                  //     variant: "danger",
                  //   },
                  // });

                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: values[0][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              }
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comment adding Failed",
            //     icon: "BellIcon",
            //     text: "Oops! Something Went Wrong",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Oops! Something Went Wrong",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            this.commentSubmitClicked = false;
          }
        });
      this.parentRefresh();
      return true;
    },
    getPostData(itemId) {
      
      if (!itemId) {
        console.log("no itemId");
        return true;
      }
      const self = this;

      let data = {};
      data.url = "/posts/" + itemId;
      if (
        !this.itemData ||
        (this.itemData && this.itemId != this.itemData.hashid)
      ) {
        self.isBusy = true;
      }
      self.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.post_data = res.data.data;
          self.isBusy = false;
        })
        .catch((error) => {
          this.show = false;
          if (error.response.status === 404) {
            // return self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Something went wrong",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: "Post not found",
            //   },
            // });

            return this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Post not found",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
          // console.log(error.response.status);
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Error",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });

        this.$http
        .get(`/posts/${itemId}/getAllComments`)
        .then((res) => {
          self.post_data.comments = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetFormData() {
      this.itemId = null;
      this.post_data = {
        user: {},
        attachments: {},
      };
      // Select the <body> element
      const body = document.body;

      // Remove a class from the <body> element
      body.classList.remove('modal-open');
    },
    openWindow(link) {
      window.open(link);
    },
    updatePostData(data) {
      self.post_data = data;
    },
    fetchItem(hashid) {
      this.$store
        .dispatch("app-tickets/fetchTicket", hashid)
        .then((res) => {
          this.data_local = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addReplyComment(comment_data) {
      this.commentSubmitClicked = true;
      const self = this;
      const comment = document.getElementById(
        `comment-field-${comment_data.hashid}`
      ).value;
      if (comment === "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Comment adding Failed",
        //     icon: "BellIcon",
        //     text: "Please enter the comments",
        //     variant: "danger",
        //   },
        // });

        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Please enter the comments",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        this.commentSubmitClicked = false;
        return false;
      }

      let params = {};
      params = {
        comment: comment,
        parent_comment_id: self.comment_id,
      };

      this.$http
        .post(`/posts/${this.itemId}/comments`, params)
        .then((res) => {
          self.post_data = res.data.data;
          self.reply_to = null;
          self.comment_id = null;
          document.getElementById(
            `comment-field-${comment_data.hashid}`
          ).value = "";
          $(".ql-editor").html("");
          this.commentSubmitClicked = false;
        })
        .catch((error) => {
          console.log(error);
          this.commentSubmitClicked = false;
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "Comment adding Failed",
                  //     icon: "BellIcon",
                  //     text: values[0][0],
                  //     variant: "danger",
                  //   },
                  // });
                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: values[0][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              }
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comment adding Failed",
            //     icon: "BellIcon",
            //     text: "Oops! Something Went Wrong",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Oops! Something Went Wrong",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            this.commentSubmitClicked = false;
          }
        });
      this.parentRefresh();
      return true;
    },
    addComment(post_data) {
      this.commentSubmitClicked = true;
      const self = this;
      const comment = document.getElementById(
        `comment-field-${post_data.hashid}`
      ).value;
      if (comment === "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Comment adding Failed",
        //     icon: "BellIcon",
        //     text: "Please enter the comments",
        //     variant: "danger",
        //   },
        // });

        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Please enter the comments",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        this.commentSubmitClicked = false;
        return false;
      }

      let params = {};
      params = {
        comment: comment,
        parent_comment_id: self.comment_id,
      };

      this.$http
        .post(`/posts/${post_data.hashid}/comments`, params)
        .then((res) => {

          self.post_data = res.data.data;
          self.reply_to = null;
          self.comment_id = null;
          document.getElementById(`comment-field-${post_data.hashid}`).value = "";
          $(".ql-editor").html("");
          this.commentSubmitClicked = false;
        })
        .catch((error) => {
          console.log(error);
          this.commentSubmitClicked = false;
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "Comment adding Failed",
                  //     icon: "BellIcon",
                  //     text: values[0][0],
                  //     variant: "danger",
                  //   },
                  // });
                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: values[0][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              }
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comment adding Failed",
            //     icon: "BellIcon",
            //     text: "Oops! Something Went Wrong",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Oops! Something Went Wrong",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            this.commentSubmitClicked = false;
          }
        });
      this.parentRefresh();
      return true;
    },
    pinPost(data) {
      axios.get("/pin-post/" + data.hashid).then((response) => {
        if (response.data.success) {
          bus.$emit("loadPosts");
          this.getPostData(this.itemId);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post Pinned",
          //     icon: "UserCheckIcon",
          //     variant: "primary",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Post Pinned',
                icon: 'UserCheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to pin post",
          //     icon: "UserXIcon",
          //     variant: "danger",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to pin post',
                icon: 'UserXIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        }
      });
      this.parentRefresh();
    },
    unpinPost(data) {
      axios.get("/unpin-post/" + data.hashid).then((response) => {
        if (response.data.success) {
          this.getPostData(this.itemId);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post Un-Pined",
          //     icon: "UserCheckIcon",
          //     variant: "primary",
          //     text: response.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Post Un-Pined',
                icon: 'UserCheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          
        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to unpin post",
          //     icon: "UserXIcon",
          //     variant: "danger",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to unpin post"',
                icon: 'UserXIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            
        }
      });
      this.parentRefresh();
    },

    postPublicURL(data) {
    let payLoad = {
      post_id: data.hashid,
    };
  this.$http
  .post(`/posts/post_public_url`, payLoad)
  .then((res) => {

    this.boxOne = '';
    const modalContent = this.$createElement('div', [
  this.$createElement('p', 'Ready to share? Copy the link to this post. Your organization and personal details will remain confidential.'),
  this.$createElement('div', { style: 'border: 1px solid #ccc;display:flex' }, [
  this.$createElement('strong', `URL: ${window.location.href.split('?')[0]}public-post/${res.data.public_post_id}`),,
    this.$createElement('button', {
      class: 'btn btn-primary',
      on: {
        click: () => {
          const copyText = `${window.location.href.split('?')[0]}public-post/${res.data.public_post_id}`;
          navigator.clipboard.writeText(copyText); // Copy text to clipboard
          this.getPostData(this.itemId)
          this.parentRefresh();
          
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: 'Success',
          //     icon: 'BellIcon',
          //     variant: 'success',
          //     text: 'Link copied to clipboard!',
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Link copied to clipboard!',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        },
      },
    }, 'Copy Link'),
  ]),
]);

    this.$bvModal.msgBoxConfirm(
      modalContent,
  {
    title: "Generate Public URL",
    cancelVariant: 'outline-secondary',
    centered: true, // Center the popup
    buttonSize: 'md',      
    hideHeaderClose: false,
    headerClass: 'bg-transparent',
    modalClass: 'public-modal',
    footerClass: 'd-none',
    hide: false ,
   'hide-footer': true

  }
).then((value) => {
      // if (value) {
      //   // Perform copy action here
      //   const copyText = `${window.location.href.split('?')[0]}public-post/${res.data.public_post_id}`;
      //   navigator.clipboard.writeText(copyText); // Copy text to clipboard
      //   this.getPosts();
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Success',
      //       icon: 'BellIcon',
      //       variant: 'success',
      //       text: 'link copied to clipboard!',
      //     },
      //   });
      // }
    });
  })
  .catch((err) => {
    if (err.response && err.response.status && err.response.status == 404) {
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: err.response.data.error,
      //     icon: 'BellIcon',
      //     variant: 'danger',
      //     text: err.toString(),
      //   },
      // });

      this.$toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.error,
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
    }
  });
},
postRevokePublicURL(data){
  this.$bvModal.msgBoxConfirm(
    'you want to revoke the public link for this post? By confirming, the link will be revoked from all places you shared it.',
    {
      title: 'Are you sure ? ',
      size: 'md',
      okVariant: 'danger',
      okTitle: 'revoke',
      cancelTitle: 'cancel',
      cancelVariant: 'outline-secondary',
      hideHeaderClose: false,
      hideFooter: true,
      centered: true,
      headerClass: 'bg-transparent',
      modalClass: 'public-modal',
      footerClass: 'modal-footer',
    }
  ).then((value) => {
      if (value) {
        this.$http
   .delete(`/posts/revoke_public_url/${data.hashid}`)
   .then((res) => {
    // this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Success',
    //         icon: 'BellIcon',
    //         variant: 'success',
    //         text: res.data.message,
    //       },
    //     });

    this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        this.getPostData(this.itemId);

  })
  .catch((err) => {
    if (err.response && err.response.status && err.response.status == 404) {
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: err.response.data.error,
      //     icon: 'BellIcon',
      //     variant: 'danger',
      //     text: err.toString(),
      //   },
      // });

      this.$toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.error,
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
    }
  });
      }
    });
    this.parentRefresh();
},
copyPublicURL(data){
  const copyText = `${window.location.href.split('?')[0]}public-post/${data}`;
        navigator.clipboard.writeText(copyText); // Copy text to clipboard
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Success',
        //     icon: 'BellIcon',
        //     variant: 'success',
        //     text: 'link copied to clipboard!',
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: 'link copied to clipboard!',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
},

    approvePost(data) {
      axios.get("post/approve/" + data.hashid).then((response) => {
        if (response.data.success) {
          this.getPostData(this.itemId);
          // this.getPosts();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post Approved",
          //     icon: "UserCheckIcon",
          //     variant: "primary",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Post Approved',
                icon: 'UserCheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to Approve post",
          //     icon: "UserXIcon",
          //     variant: "danger",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to Approve post',
                icon: 'UserXIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        }
      });
      this.parentRefresh();
    },
    rejectPost(data) {
      axios.get("post/reject/" + data.hashid).then((response) => {
        if (response.data.success) {
          this.getPostData(this.itemId);
          // this.getPosts();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post Reject",
          //     icon: "UserCheckIcon",
          //     variant: "primary",
          //     text: response.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Post Reject',
                icon: 'UserCheckIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to Reject post",
          //     icon: "UserXIcon",
          //     variant: "danger",
          //     text: response.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to Reject post',
                icon: 'UserCheckIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        }
      });
      this.parentRefresh();
    },
    updatePostArray(data) {
      self.post_data = data;
    },

    like(comment_id, index, type) {
      const self = this;
      let data = {};
      data.url = "comments/" + comment_id + "/like/" + type;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.getPostData(this.itemId);
        })
        .catch((err) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "liking failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'liking failed',
                icon: 'BellIcon',
                variant: 'danger',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"error-info ",
              hideProgressBar : true,
            });
          console.error(err);
        });
      this.parentRefresh();
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete this post ${data.title }.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.hashid);
        }
      });
    },
    deleteRecord(id) {
      let data = {};
      data.url = "posts/" + id;
      this.$store
        .dispatch("app/delete", data)
        .then((res) => {
          if (res.status == "204") {
            // document.getElementById("post-card-" + id).remove();
            this.parentRefresh();
            this.$refs.sidebar_view.hide();
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Post Deleted",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "Post Deleted Successfully.",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Post Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Post Deleted Successfully.',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Post Deletion failed.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Post Deletion failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
          // this.refetchData();
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Post Deletion failed.1",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Post Deletion failed.`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          console.error(err);
        });
    },
    parentRefresh(){
      this.$emit('triggerParentRefresh');
    },
    triggerEdit(data){
      this.$emit('triggerEditPost',data);

    },
    triggerPostNow(data){
      this.$emit('triggerPostNow',data);

    }
  },
};
</script>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
.flatpickr-input {
  background: whitesmoke !important;
}
</style>

